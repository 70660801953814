import React from "react";
import {NormalLayout} from "../../../components/layouts/normal_layout";
import {Introduction} from "../../../components/products/skeptron/skeptron_t/introduction";
import {Benefits} from "../../../components/products/skeptron/skeptron_t/benefits";
import {Reasons} from "../../../components/products/skeptron/skeptron_t/reasons";
import {Details} from "../../../components/products/skeptron/skeptron_t/details";
import {Applications} from "../../../components/products/skeptron/skeptron_t/applications";
import {SEO} from "../../../components/seo";

const SkeptronT: React.FC = () => {

  return (
    <NormalLayout type="product">
      <SEO title="Skeptron T | UV Tech by Rendev" description="Skeptron T | UV Tech by Rendev"/>
      <Introduction/>
      <Benefits/>
      <Reasons/>
      <Details/>
      <Applications/>
    </NormalLayout>
  )
}

export default SkeptronT;