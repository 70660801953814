import React from "react";
import {useTranslation} from "react-i18next";
import {Section} from "../../../common/section";
import {SocialButtons} from "../../../common/buttons/social";
import {graphql, useStaticQuery} from "gatsby";
import GatsbyImage from "gatsby-image";

export const Reasons: React.FC = () => {
  const {t} = useTranslation();

  const data = useStaticQuery(graphql`
  query {
  reasons: file(relativePath: {eq: "skeptron/skeptron_t-reasons.png"}) { ...squareImage }
  }`)

  return (
    <Section className="reasons">
      <div className="app">
        <div className="app-info">
          <h2>{t('products.skeptron.skeptron_t.reasons.heading')}</h2>

          <h4>{t('products.skeptron.skeptron_t.reasons.heading1')}</h4>
          <p>{t('products.skeptron.skeptron_t.reasons.description1')}</p>

          <h4>{t('products.skeptron.skeptron_t.reasons.heading2')}</h4>
          <p>{t('products.skeptron.skeptron_t.reasons.description2')}</p>

          <h4>{t('products.skeptron.skeptron_t.reasons.heading3')}</h4>
          <p>{t('products.skeptron.skeptron_t.reasons.description3')}</p>
        </div>
        <div className="app-image">
          <GatsbyImage
            style={{height: "100%", width: "100%"}}
            fluid={data.reasons.childImageSharp.fluid}
          />
        </div>
        <SocialButtons/>
      </div>
    </Section>
  )
}
